<template xmlns="http://www.w3.org/1999/html">
  <section>
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-5 sm:mb-10">
        <p class="text-center text-3xl font-bold">{{ headlineText }}</p>
      </div>
      <div class="mx-auto mb-8">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <!-- Corrected from <tem> to <div> -->
      <template v-if="model.step === 'services_baths_start'">
        <div class="mb-20">
          <Headline>Wie viele Badezimmer/Gäste-WCs haben Sie?</Headline>
          <div class="mx-auto mt-14">
            <div class="mb-4 flex flex-row items-center justify-between">
              <span class="text-md font-semibold">Anzahl der Badezimmer/Gäste-WCs:</span>
              <CounterInput v-model="bathCreator" />
            </div>
          </div>
          <SingleNavigation :step="getNextStep" v-model="model" :disabled="!hasSelectedRooms" />
        </div>
      </template>

      <template
        v-for="([roomId, roomConfig], index) in Object.entries(storedModel.change.roomConfigs)"
        :key="roomId"
      >
        <template v-if="model.step === `services_baths_size_${roomId}`"
          ><Headline
            >Wie groß ist der folgende Raum:
            <span class="font-light">{{ getRoomNameWithIndex('Badezimmer', roomId) }}</span
            >?</Headline
          >

          <div class="my-20 space-y-16">
            <Range v-model="model.rooms.find((r) => r.id === roomId).areas.floor" :max="80" />
          </div>
          <SingleNavigation
            :step="`services_baths_floor_${roomId}`"
            :prev="
              Object.keys(storedModel.change.roomConfigs).indexOf(roomId) === 0
                ? 'services_baths_start'
                : `services_baths_extras_${
                    Object.keys(storedModel.change.roomConfigs)[
                      Object.keys(storedModel.change.roomConfigs).indexOf(roomId) - 1
                    ]
                  }`
            "
            v-model="model"
            :disabled="!model.rooms.find((r) => r.id === roomId).areas.floor"
          />
        </template>

        <template v-if="model.step === `services_baths_floor_${roomId}`">
          <Headline
            >Soll der Boden gefliest werden:
            <span class="font-light">{{ getRoomNameWithIndex('Badezimmer', roomId) }}</span
            >?</Headline
          >
          <IconsOptionsSelection :options="yesNo" v-model="floorComputed" cols="md:grid-cols-2" />
          <SingleNavigation
            v-if="floorComputed"
            :step="`services_baths_floor_quality_${roomId}`"
            :prev="`services_baths_size_${roomId}`"
            v-model="model"
          />
          <SingleNavigation
            v-else
            :step="`services_baths_walls_${roomId}`"
            :prev="`services_baths_size_${roomId}`"
            v-model="model"
          />
        </template>
        <template v-if="model.step === `services_baths_floor_quality_${roomId}`">
          <Headline
            >In welcher Qualität wünschen Sie sich die neuen Fliesen:
            <span class="font-light">{{ getRoomNameWithIndex('Badezimmer', roomId) }}</span
            >?</Headline
          >
          <IconsOptionsSelection :options="floors" v-model="floorValue" cols="md:grid-cols-3" />
          <SingleNavigation
            :step="`services_baths_walls_${roomId}`"
            :prev="`services_baths_floor_${roomId}`"
            v-model="model"
          />
        </template>

        <template v-if="model.step === `services_baths_walls_${roomId}`">
          <Headline
            >Sollen die Wände gefliest werden:
            <span class="font-light">{{ getRoomNameWithIndex('Badezimmer', roomId) }}</span
            >?</Headline
          >
          <IconsOptionsSelection :options="yesNo" v-model="wallComputed" cols="md:grid-cols-2" />
          <SingleNavigation
            v-if="floorComputed & wallComputed"
            :step="`services_baths_walls_quality_${roomId}`"
            :prev="`services_baths_floor_quality_${roomId}`"
            v-model="model"
          />
          <SingleNavigation
            v-else-if="!floorComputed & wallComputed"
            :step="`services_baths_walls_quality_${roomId}`"
            :prev="`services_baths_floor_${roomId}`"
            v-model="model"
          />
          <SingleNavigation
            v-else-if="floorComputed & !wallComputed"
            :step="`services_baths_interior_${roomId}`"
            :prev="`services_baths_floor_quality_${roomId}`"
            v-model="model"
          />
          <SingleNavigation
            v-else
            :step="`services_baths_interior_${roomId}`"
            :prev="`services_baths_floor_${roomId}`"
            v-model="model"
          />
        </template>

        <template v-if="model.step === `services_baths_walls_quality_${roomId}`">
          <Headline
            >In welcher Qualität wünschen Sie sich die neuen Fliesen:
            <span class="font-light">{{ getRoomNameWithIndex('Badezimmer', roomId) }}</span
            >?</Headline
          >
          <IconsOptionsSelection :options="walls" v-model="wallValue" cols="md:grid-cols-3" />
          <SingleNavigation
            :step="`services_baths_wall-range_${roomId}`"
            :prev="`services_baths_walls_${roomId}`"
            v-model="model"
          />
        </template>

        <template v-if="model.step === `services_baths_wall-range_${roomId}`">
          <Headline class="mt-10"
            >Wie viel Quadratmeter Wand sollen gefliest werden:
            <span class="font-light">{{ getRoomNameWithIndex('Badezimmer', roomId) }}</span
            >?</Headline
          >
          <Range v-model="wallRange" :max="100" />
          <SingleNavigation
            :step="`services_baths_interior_${roomId}`"
            :prev="`services_baths_walls_quality_${roomId}`"
            v-model="model"
          />
        </template>

        <template v-if="model.step === `services_baths_interior_${roomId}`">
          <Headline
            >Welche Ausstattung wünschen Sie sich:
            <span class="font-light">{{ getRoomNameWithIndex('Badezimmer', roomId) }}</span
            >?</Headline
          >
          <IconsOptionsSelection
            :options="interiors"
            v-model="interiorValue"
            multiple
            cols="md:grid-cols-5"
            mobileCold="grid-cols-1"
          >
            <template #option-slot="{ option, isActive }">
              <QualityOptionsSelection
                class="my-5 w-full"
                v-if="isActive"
                :options="qualities"
                :model-value="getQualityForType(option.value)"
                @update:modelValue="(v) => setQualityForType(option.value, v)"
              />
            </template>
          </IconsOptionsSelection>
          <SingleNavigation
            v-if="wallComputed"
            :step="`services_baths_extras_${roomId}`"
            :prev="`services_baths_wall-range_${roomId}`"
            v-model="model"
          />
          <SingleNavigation
            v-else
            :step="`services_baths_extras_${roomId}`"
            :prev="`services_baths_walls_${roomId}`"
            v-model="model"
          />
        </template>

        <template v-if="model.step === `services_baths_extras_${roomId}`">
          <div class="mb-20">
            <Headline
              >Welche zusätzlichen Wünsche haben Sie:
              <span class="font-light">{{ getRoomNameWithIndex('Badezimmer', roomId) }}</span
              >?</Headline
            >
            <IconsOptionsSelection
              :options="extras"
              cols="md:grid-cols-2"
              v-model="extrasValue"
              multiple
            />
            <SingleNavigation
              :step="
                index === Object.keys(storedModel.change.roomConfigs).length - 1
                  ? 'services_baths_final'
                  : `services_baths_size_${Object.keys(storedModel.change.roomConfigs)[index + 1]}`
              "
              :prev="`services_baths_interior_${roomId}`"
              v-model="model"
            />
          </div>
        </template>
      </template>
    </form>
  </section>
</template>
<script setup>
import { ContactValidationSchema } from '@/utils/validators.js'
import { computed, onMounted, ref, watch } from 'vue'
import track from '@/utils/tracking.js'
import Headline from '@/components/Structure/Singles/Headline.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import { getImageUrl } from '@/utils/image.js'
import createRoom from '@/utils/room'
import { useLocalStorage } from '@vueuse/core'
import { getGclid } from '@/utils/gclid'
import QualityOptionsSelection from '@/components/Inputs/QualityOptionsSelection.vue'
import Range from '@/components/Inputs/Singles/Range.vue'
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import { configTemplate, yesNo, interiors, floors, qualities } from '@/data/options/v2/baths.js'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import PartitionImage from '@public/images/singles/bath/x_partition.svg'
import CabinetImage from '@public/images/singles/bath/x_cabinet.svg'
import TowelHeatingImage from '@public/images/singles/bath/x_towel_heating.svg'
import SpotsImage from '@public/images/singles/bath/x_spots.svg'
const API_URL = import.meta.env.VITE_API_URL
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const storedModel = useLocalStorage('baths_service_model', { ...configTemplate })

const currentProgress = computed(() => {
  const baseSteps = ['services_baths_start']

  const roomSteps = [
    'services_baths_size',
    'services_baths_floor',
    'services_baths_floor_quality',
    'services_baths_walls',
    'services_baths_walls_quality',
    'services_baths_wall-range',
    'services_baths_interior',
    'services_baths_extras'
  ]

  const postRoomSteps = ['services_baths_final']

  // Generate steps for each room
  const roomSpecificSteps = Object.keys(storedModel.value.change.roomConfigs).flatMap((roomId) =>
    roomSteps.map((step) => `${step}_${roomId}`)
  )

  // Combine all steps in the correct order
  const steps = [...baseSteps, ...roomSpecificSteps, ...postRoomSteps]

  // Find the current step index
  const currentStepIndex = steps.findIndex((step) => model.value.step === step)

  if (currentStepIndex === -1) return 0

  if (model.value.step === 'services_baths_final') {
    return 100
  }

  // Calculate progress percentage
  return Math.round((currentStepIndex / (steps.length - 1)) * 100)
})

const getRoomNameWithIndex = (roomType, roomId) => {
  const roomIndex = Object.keys(storedModel.value.change.roomConfigs).indexOf(roomId) + 1
  const totalRooms = Object.keys(storedModel.value.change.roomConfigs).length
  return `${roomType} (${roomIndex}/${totalRooms})`
}

const getNextStep = computed(() => {
  // Check if there are any room configs in the object
  if (Object.keys(model.value.services.baths.change.roomConfigs).length > 0) {
    // If there are room configs, return the 'services_baths_size_{roomId}' step
    // using the first room ID in the object
    const firstRoomId = Object.keys(model.value.services.baths.change.roomConfigs)[0]
    return `services_baths_size_${firstRoomId}`
  }
  // If there are no room configs, return the 'services_floors_start' step
  return 'services_baths_start'
})

const bathAmount = ref(0)
//save it to localstorage

const bathCreator = computed({
  get: () => {
    return Object.keys(storedModel.value.change.roomConfigs).length
  },
  set: (v) => {
    const currentLength = Object.keys(storedModel.value.change.roomConfigs).length

    if (v > currentLength) {
      // Add new rooms
      for (let i = currentLength; i < v; i++) {
        const room = createRoom('Badezimmer', 'bathroom', false, true)
        model.value.rooms.push(room)
        storedModel.value.change.roomConfigs[room.id] = {
          ...JSON.parse(JSON.stringify(roomConfig.value))
        }
        storedModel.value.rooms.push(room.id)
        model.value.services.baths.change.roomConfigs[room.id] =
          storedModel.value.change.roomConfigs[room.id]
      }
    } else if (v < currentLength) {
      // Remove excess rooms
      const roomIds = Object.keys(storedModel.value.change.roomConfigs)
      for (let i = currentLength - 1; i >= v; i--) {
        const roomId = roomIds[i]
        delete storedModel.value.change.roomConfigs[roomId]
        delete model.value.services.baths.change.roomConfigs[roomId]
        storedModel.value.rooms = storedModel.value.rooms.filter((id) => id !== roomId)
        model.value.rooms = model.value.rooms.filter((room) => room.id !== roomId)
      }
    }

    // Update bathAmount
    bathAmount.value = Object.keys(storedModel.value.change.roomConfigs).length
  }
})

const wallRange = computed({
  get: () => {
    const currentRoom = model.value.services.baths.change.currentRoom
    return model.value.services.baths.change.roomConfigs[currentRoom].walls.size
  },
  set: (v) => {
    const currentRoom = model.value.services.baths.change.currentRoom
    model.value.services.baths.change.roomConfigs[currentRoom].walls.size = v
  }
})

const floorComputed = computed({
  get: () => {
    const currentRoom = model.value.services.baths.change.currentRoom
    return model.value.services.baths.change.roomConfigs[currentRoom].floor.enabled
  },
  set: (v) => {
    const currentRoom = model.value.services.baths.change.currentRoom
    model.value.services.baths.change.roomConfigs[currentRoom].floor.enabled = v
  }
})

const floorValue = computed({
  get: () => {
    const currentRoom = model.value.services.baths.change.currentRoom
    return model.value.services.baths.change.roomConfigs[currentRoom].floor.enabled
      ? model.value.services.baths.change.roomConfigs[currentRoom].floor.quality
      : ''
  },
  set: (v) => {
    const currentRoom = model.value.services.baths.change.currentRoom
    model.value.services.baths.change.roomConfigs[currentRoom].floor.enabled = v ? true : false
    model.value.services.baths.change.roomConfigs[currentRoom].floor.quality = v
  }
})

const wallComputed = computed({
  get: () => {
    const currentRoom = model.value.services.baths.change.currentRoom
    return model.value.services.baths.change.roomConfigs[currentRoom].walls.enabled
  },
  set: (v) => {
    const currentRoom = model.value.services.baths.change.currentRoom
    model.value.services.baths.change.roomConfigs[currentRoom].walls.enabled = v
  }
})

const wallValue = computed({
  get: () => {
    const currentRoom = model.value.services.baths.change.currentRoom
    return model.value.services.baths.change.roomConfigs[currentRoom].walls.enabled
      ? model.value.services.baths.change.roomConfigs[currentRoom].walls.quality
      : ''
  },
  set: (v) => {
    const currentRoom = model.value.services.baths.change.currentRoom
    model.value.services.baths.change.roomConfigs[currentRoom].walls.enabled = v ? true : false
    model.value.services.baths.change.roomConfigs[currentRoom].walls.quality = v
  }
})

const walls = floors

const getQualityForType = (type) => {
  const currentRoom = model.value.services.baths.change.currentRoom
  const roomConfig = model.value.services.baths.change.roomConfigs[currentRoom]
  if (type === 'doublesink') {
    return roomConfig.sink.tub.quality
  }
  if (type === 'toilet') {
    return roomConfig.toilet.quality
  }

  return roomConfig[type].tub.quality
}

const setQualityForType = (type, quality) => {
  const currentRoom = model.value.services.baths.change.currentRoom
  const roomConfig = model.value.services.baths.change.roomConfigs[currentRoom]
  if (type === 'doublesink') {
    roomConfig.sink.tub.quality = quality
    roomConfig.sink.handle.quality = quality
    return
  }

  if (type === 'toilet') {
    roomConfig.toilet.quality = quality
    return
  }

  roomConfig[type].tub.quality = quality
  roomConfig[type].tub.quality = quality
}

const interiorValue = computed({
  get: () => {
    const values = []
    const currentRoom = model.value.services.baths.change.currentRoom
    const currentRoomConfig = model.value.services.baths.change.roomConfigs[currentRoom]

    if (currentRoomConfig && currentRoomConfig.shower?.enabled) values.push('shower')
    if (currentRoomConfig && currentRoomConfig.bathtub?.enabled) values.push('bathtub')
    if (currentRoomConfig && currentRoomConfig.toilet?.enabled) values.push('toilet')
    if (
      currentRoomConfig &&
      currentRoomConfig.sink?.enabled &&
      currentRoomConfig.sink?.type === 'single'
    )
      values.push('sink')
    if (
      currentRoomConfig &&
      currentRoomConfig.sink?.enabled &&
      currentRoomConfig.sink?.type === 'double'
    )
      values.push('doublesink')

    return values
  },
  set: (v) => {
    const currentRoom = model.value.services.baths.change.currentRoom
    const currentRoomConfig = model.value.services.baths.change.roomConfigs[currentRoom]

    if (!currentRoomConfig) return // Update simple toggles
    ;['shower', 'bathtub', 'toilet'].forEach((item) => {
      currentRoomConfig[item].enabled = v.includes(item)
    })

    // Handle sink configuration
    const hasSink = v.includes('sink')
    const hasDoubleSink = v.includes('doublesink')

    currentRoomConfig.sink.enabled = hasSink || hasDoubleSink

    if (currentRoomConfig.sink.enabled) {
      if (hasSink && hasDoubleSink) {
        currentRoomConfig.sink.type =
          v.indexOf('doublesink') > v.indexOf('sink') ? 'double' : 'single'
      } else {
        currentRoomConfig.sink.type = hasDoubleSink ? 'double' : 'single'
      }
    }
  }
})

const extras = computed(() => {
  const values = []
  const currentRoom = model.value.services.baths.change.currentRoom
  const roomConfig = model.value.services.baths.change.roomConfigs[currentRoom]

  if (roomConfig.shower.enabled) {
    values.push({
      label: 'Duschtrennwand',
      image: PartitionImage,
      value: 'compartment'
    })
  }

/*  if (roomConfig.sink.enabled) {
    values.push({
      label: 'Waschtisch-unterschrank',
      image: CabinetImage,
      value: 'baseCabinet'
    })
  }*/

  values.push({
    label: 'Handtuchheizkörper',
    image: TowelHeatingImage,
    value: 'towel_heating'
  })

  values.push({
    label: 'Decke mit Einbaustrahler',
    image: SpotsImage,
    value: 'ceiling'
  })

  return values
})

const extrasValue = computed({
  get: () => {
    const currentRoom = model.value.services.baths.change.currentRoom
    const roomConfig = model.value.services.baths.change.roomConfigs[currentRoom]
    const values = []

    if (roomConfig.shower.modification.partitionNoDoor) values.push('partition')
    if (roomConfig.shower.modification.compartment) values.push('compartment')
    if (roomConfig.sink.baseCabinet) values.push('baseCabinet')

    if (
      model.value.services.baths.radiator.items.some(
        (item) => item.value === 'towel_radiator_compact'
      ) &&
      model.value.services.baths.radiator.rooms.includes(currentRoom)
    ) {
      values.push('towel_heating')
    }
    if (model.value.services.heating.underfloor_heating.enabled) values.push('floor_heating')
    if (model.value.services.baths.ceilings.ceilingSpots.rooms.includes(currentRoom))
      values.push('ceiling')

    return values
  },
  set: (v) => {
    const currentRoom = model.value.services.baths.change.currentRoom
    const roomConfig = model.value.services.baths.change.roomConfigs[currentRoom]
    roomConfig.shower.modification.partitionNoDoor = v.includes('partition')
    roomConfig.shower.modification.compartment = v.includes('compartment')
    roomConfig.sink.baseCabinet = v.includes('baseCabinet')

    // Handle towel heating
    if (v.includes('towel_heating')) {
      model.value.services.baths.radiator.enabled = true
      const existingItem = model.value.services.baths.radiator.items.find(
        (item) => item.value === 'towel_radiator_compact'
      )

      if (!existingItem) {
        model.value.services.baths.radiator.items.push({
          label: 'x',
          value: 'towel_radiator_compact',
          amount: 1,
          image: 'x'
        })
      }

      if (!model.value.services.baths.radiator.rooms.includes(currentRoom)) {
        model.value.services.baths.radiator.rooms.push(currentRoom)
      }

      // Set the amount to match the number of rooms
      if (existingItem) {
        existingItem.amount = model.value.services.baths.radiator.rooms.length
      }
    } else {
      model.value.services.baths.radiator.rooms = model.value.services.baths.radiator.rooms.filter(
        (room) => room !== currentRoom
      )

      if (model.value.services.baths.radiator.rooms.length === 0) {
        model.value.services.baths.radiator.enabled = false
        model.value.services.baths.radiator.items =
          model.value.services.baths.radiator.items.filter(
            (item) => item.value !== 'towel_radiator_compact'
          )
      } else {
        const existingItem = model.value.services.baths.radiator.items.find(
          (item) => item.value === 'towel_radiator_compact'
        )
        if (existingItem) {
          existingItem.amount = model.value.services.baths.radiator.rooms.length
        }
      }
    }

    // Handle underfloor heating
    model.value.services.heating.underfloor_heating.enabled = v.includes('floor_heating')

    // Handle ceiling spots
    if (v.includes('ceiling')) {
      model.value.services.baths.ceilings.enabled = true
      model.value.services.baths.ceilings.ceilingSpots.enabled = true
      if (!model.value.services.baths.ceilings.ceilingSpots.rooms.includes(currentRoom)) {
        model.value.services.baths.ceilings.ceilingSpots.rooms.push(currentRoom)
      }
    } else {
      model.value.services.baths.ceilings.ceilingSpots.rooms =
        model.value.services.baths.ceilings.ceilingSpots.rooms.filter(
          (room) => room !== currentRoom
        )
      if (model.value.services.baths.ceilings.ceilingSpots.rooms.length === 0) {
        model.value.services.baths.ceilings.enabled = false
        model.value.services.baths.ceilings.ceilingSpots.enabled = false
      }
    }
  }
})

const submitting = ref(false)
const submitted = ref(false)

watch(
  () => model.value.step,
  (v) => {
    const roomId = v.split('_').pop()
    if (roomId) {
      model.value.services.baths.change.currentRoom = roomId
    }
  }
)

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  model.value.contact.gclid = getGclid()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(props.modelValue)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'bath',
              content_name: 'bath'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: model.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('bath', data.volume / 100, 'EUR', data.id)
    })
    .finally(() => {
      submitting.value = false
    })
}

const nextStep = (step) => {
  if (window.innerWidth < 1024) {
    model.value.step = step
  }
}

const room = useLocalStorage('form_bath_room', createRoom('Badezimer', 'bathroom', false, true))
const roomConfig = useLocalStorage(
  'form_path_roomConfig',
  JSON.parse(JSON.stringify(model.value.services.baths.change.config))
)

const hasSelectedRooms = computed(() => {
  return bathCreator.value > 0
})

onMounted(() => {
  if (
    model.value.services.baths.change.roomConfigs &&
    Object.keys(model.value.services.baths.change.roomConfigs).length === 0
  ) {
    model.value.services.baths.change.roomConfigs = storedModel.value.change.roomConfigs
  }

  model.value.services.baths.change.currentRoom = ''
  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'bath',
        content_name: 'bath'
      }
    ]
  })
  track.tmViewItem('bath')

  model.value.setup = true
  model.value.step = 'services_baths_start'
  model.value.services.baths.enabled = true
  model.value.services.baths.change.enabled = true
})
</script>
<style lang="postcss">
.small-qualities {
  img {
    @apply hidden;
  }

  @media (max-width: 1024px) {
    label {
      @apply ml-0 w-full pr-0 text-center;
    }

    [data-section='icon'] {
      @apply hidden;
    }
  }
}
</style>
