<template>
  <section>
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-10">
        <Headline>{{ headlineText }}</Headline>
      </div>
      <div class="mx-auto mb-8">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <template v-if="model.step === 'services_stairs_start'">
        <Headline>Soll eine neue Treppe verbaut werden?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="yesNoOptions"
          v-model="model.services.stairs.rebuild.enabled"
        />
        <SingleNavigation
          v-if="model.services.stairs.rebuild.enabled"
          step="services_stairs_stairs"
          v-model="model"
        />
        <SingleNavigation v-else step="services_stairs_renew" v-model="model" />
      </template>
      <template v-else-if="model.step === 'services_stairs_stairs'">
        <Headline>Welche Treppe wünschen Sie sich?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="stairs"
          v-model="model.services.stairs.rebuild.type"
        />
        <SingleNavigation
          step="services_stairs_quality"
          prev="services_stairs_start"
          v-model="model"
          :disabled="!model.base.house_type"
        />
      </template>
      <template
        v-else-if="
          model.step === 'services_stairs_quality' && model.services.stairs.rebuild.enabled
        "
      >
        <Headline>In welcher Qualität wünschen sie sich die neue Treppe?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-3"
          :options="qualities"
          v-model="model.services.stairs.rebuild.quality"
        />
        <SingleNavigation
          step="services_stairs_renew"
          prev="services_stairs_stairs"
          v-model="model"
          :disabled="!model.base.house_type"
        />
      </template>
      <template v-else-if="model.step === 'services_stairs_renew'">
        <Headline>Soll eine bestehende Treppe aufbereitet werden?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="yesNoOptions"
          v-model="model.services.stairs.renew.enabled"
        />
        <SingleNavigation
          v-if="model.services.stairs.renew.enabled && model.services.stairs.rebuild.enabled"
          step="services_stairs_lack"
          prev="services_stairs_quality"
          v-model="model"
          :disabled="!model.base.house_type"
        />
        <SingleNavigation
          v-else-if="model.services.stairs.renew.enabled && !model.services.stairs.rebuild.enabled"
          step="services_stairs_lack"
          prev="services_stairs_start"
          v-model="model"
          :disabled="!model.base.house_type"
        />
        <SingleNavigation
          v-else
          step="services_stairs_addWoodSteps"
          prev="services_stairs_quality"
          v-model="model"
          :disabled="!model.base.house_type"
        />
      </template>
      <template v-else-if="model.step === 'services_stairs_lack'">
        <Headline>Soll die Treppe lackiert werden?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="yesNoOptions"
          v-model="model.services.stairs.renew.lack.enabled"
        />
        <div
          v-if="model.services.stairs.renew.lack.enabled"
          class="my-14 flex flex-row items-center justify-between"
        >
          <span class="font-semibold">Anzahl der Stufen:</span>
          <div class="flex flex-row items-center">
            <span class="mr-1">Anzahl:</span>
            <CounterInput v-model="stairsCountComputed" />
          </div>
        </div>
        <SingleNavigation
          step="services_stairs_addWoodSteps"
          prev="services_stairs_renew"
          v-model="model"
        />
      </template>
      <template v-else-if="model.step === 'services_stairs_addWoodSteps'">
        <Headline>Soll eine Betontreppe mit massiven Holzstufen belegt werden?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="yesNoOptions"
          v-model="model.services.stairs.renew.addWoodSteps.enabled"
        />
        <div
          v-if="model.services.stairs.renew.addWoodSteps.enabled"
          class="my-14 flex flex-row items-center justify-between"
        >
          <span class="font-semibold">Anzahl der Stufen:</span>
          <div class="flex flex-row items-center">
            <span class="mr-1">Anzahl:</span>
            <CounterInput v-model="model.services.stairs.renew.addWoodSteps.amount" />
          </div>
        </div>
        <SingleNavigation
          step="services_stairs_final"
          prev="services_stairs_lack"
          v-model="model"
          :disabled="
            !model.services.stairs.renew.addWoodSteps.amount &&
            model.services.stairs.renew.addWoodSteps.enabled
          "
        />
      </template>
    </form>
  </section>
</template>
<script setup>
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import track from '@/utils/tracking.js'
import Headline from '@/components/Structure/Singles/Headline.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import { computed, onMounted, ref } from 'vue'
import { getImageUrl } from '@/utils/image.js'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import { yesNoOptions, qualities, stairs } from '@/data/options/v2/stairs.js'
import { ContactValidationSchema } from '@/utils/validators.js'

const props = defineProps({
  modelValue: {},
  totals: {},
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  switch (model.value.step) {
    case 'services_stairs_start':
      return 0
    case 'services_stairs_stairs':
      return 20
    case 'services_stairs_quality':
      return 40
    case 'services_stairs_renew':
      return 60
    case 'services_stairs_lack':
      return 70
    case 'services_stairs_addWoodSteps':
      return 80
    case 'services_stairs_final':
      return 100
    default:
      return 0
  }
})

const stairsCount = ref(0)
const stairsCountComputed = computed({
  get: () => stairsCount.value,
  set: (v) => {
    stairsCount.value = v
  }
})

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  model.value.contact.gclid = getGclid()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(props.modelValue)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'stairs',
              content_name: 'stairs'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: model.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('stairs', data.volume / 100, 'EUR', data.id)
    })
    .finally(() => {
      submitting.value = false
    })
}

onMounted(() => {
  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'stairs',
        content_name: 'stairs'
      }
    ]
  })
  track.tmViewItem('stairs')

  model.value.services.stairs.enabled = true
  model.value.step = 'services_stairs_start'
})
</script>
