<template>
  <section>
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-10">
        <Headline>{{ headlineText }}</Headline>
      </div>
      <div class="mx-auto mb-8">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <template v-if="model.step === 'services_facade_start'">
        <Headline>Was für ein Haus haben Sie?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="houseTypes"
          v-model="model.base.house_type"
        />
        <SingleNavigation
          step="services_facade_facade"
          v-model="model"
          :disabled="!model.base.house_type"
        />
      </template>
      <template v-else-if="model.step === 'services_facade_facade'">
        <Headline>Welche Fassade wünschen Sie sich?</Headline>
        <IconsOptionsSelection
          :options="facades"
          cols="md:grid-cols-2"
          v-model="model.services.facade.renew.type"
        />
        <SingleNavigation
          step="services_facade_size"
          prev="services_facade_start"
          v-model="model"
          :disabled="!model.services.facade.renew.type"
        />
      </template>
      <template v-else-if="model.step === 'services_facade_size'">
        <Headline>Teilen Sie uns die Grunddaten zu Ihrem Haus mit</Headline>
        <div class="mt-10 space-y-16">
          <div>
            <label class="mb-5 block text-center text-xl">
              Wieviel Quadratmeter Fassade haben Sie ca.?
            </label>
            <Range v-model="model.services.facade.renew.size" :max="500" />
          </div>
          <div>
            <label class="mb-5 block text-center text-xl">
              Um welches Baujahr handelt es sich?</label
            >
            <Range
              v-model="model.base.built"
              suffix=" "
              :formatter="(v) => v"
              :min="1900"
              :max="2024"
            />
          </div>
        </div>

        <SingleNavigation
          prev="services_facade_facade"
          step="services_facade_final"
          v-model="model"
          :disabled="!model.base.built || !model.services.facade.renew.size"
        />
      </template>
    </form>
  </section>
</template>
<script setup>
import { z } from 'zod'
import { computed, onMounted, ref, watch } from 'vue'
import track from '@/utils/tracking.js'
import Headline from '@/components/Structure/Singles/Headline.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import { scrollToElement } from '@/utils/scroll'
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import { getGclid } from '@/utils/gclid'
import Range from '@/components/Inputs/Singles/Range.vue'
import { houseTypes, facades } from '@/data/options/v2/facade'
import { ContactValidationSchema } from '@/utils/validators.js'

const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {},
  totals: {},
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  switch (model.value.step) {
    case 'services_facade_start':
      return 0
    case 'services_facade_facade':
      return 30
    case 'services_facade_size':
      return 60
    case 'services_facade_final':
      return 100
    default:
      return 0
  }
})

const submitting = ref(false)
const submitted = ref(false)

const financialSupport = computed(() => {
  return Math.min(props.totals.total.value * 0.7, 21000)
})

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  model.value.contact.gclid = getGclid()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(props.modelValue)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'facade',
              content_name: 'facade'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: model.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('facade', data.volume / 100, 'EUR', data.id)
    })
    .finally(() => {
      submitting.value = false
    })
}

onMounted(() => {
  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'facade',
        content_name: 'facade'
      }
    ]
  })
  track.tmViewItem('facade')

  if (!model.value.setup_) {
    model.value.setup = true
    model.value.step = 'services_facade_start'
    model.value.base.type = 'house'
    model.value.base.built = '1973'
    model.value.base.usage = 1
    model.value.services.facade.enabled = true
    model.value.services.facade.renew.enabled = true
    model.value.services.facade.renew.size = 120
  }
})
</script>
