<template>
  <section>
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-5 sm:mb-10">
        <p class="text-center text-3xl font-bold">{{ headlineText }}</p>
      </div>
      <div class="mx-auto mb-8">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <template v-if="model.step === 'services_floors_start'">
        <div class="mb-20">
          <Headline>In welchen Zimmern soll neuer Boden verlegt oder aufbereitet werden?</Headline>
          <div class="mx-auto mb-xl">
            <IconsOptionsSelection
              :options="roomTypes"
              cols="md:grid-cols-3"
              v-model="selectedRoomTypesComputed"
              multiple
            >
              <template #option-slot="{ option, isActive }">
                <div v-if="isActive" class="mt-3 flex w-full flex-row items-center justify-between">
                  <span class="font-semibold">Anzahl:</span>
                  <CounterInput v-model="roomAmounts[option.value]" />
                </div>
              </template>
            </IconsOptionsSelection>
          </div>
          <SingleNavigation :step="getNextStep" v-model="model" :disabled="!hasSelectedRooms" />
        </div>
      </template>

      <template v-for="(room, index) in storedModel.rooms" :key="room.id">
        <template v-if="model.step === `services_floors_size_${room.id}`">
          <Headline
            >Wie groß ist der folgende Raum:
            <span class="font-light">{{
              getRoomNameWithIndex(room.name, getSortedIndex(room))
            }}</span
            >?</Headline
          >
          <div class="my-20 space-y-16">
            <Range
              v-model="room.areas.floor"
              :max="80"
              @update:modelValue="updateRoomSize(room.id)"
            />
          </div>
          <SingleNavigation
            :step="`services_floors_task_${room.id}`"
            :prev="
              index === 0
                ? 'services_floors_start'
                : `services_floors_old_${model.rooms[index - 1].id}`
            "
            v-model="model"
            :disabled="!room.areas.floor"
          />
        </template>

        <template v-if="model.step === `services_floors_task_${room.id}`">
          <Headline
            >Was soll gemacht werden:
            <span class="font-light">{{
              getRoomNameWithIndex(room.name, getSortedIndex(room))
            }}</span
            >?</Headline
          >
          <IconsOptionsSelection :options="types" cols="md:grid-cols-2" v-model="roomTask" />
          <SingleNavigation
            v-if="isChange(room.id)"
            :step="`services_floors_type_${room.id}`"
            :prev="`services_floors_size_${room.id}`"
            v-model="model"
            :disabled="!roomTask"
          />
          <SingleNavigation
            v-else-if="isRecycleWoodenFloor(room.id)"
            :step="`services_floors_boards_${room.id}`"
            :prev="`services_floors_size_${room.id}`"
            v-model="model"
            :disabled="!roomTask"
          />
          <SingleNavigation
            v-else
            :step="`services_floors_boards_${room.id}`"
            :prev="`services_floors_size_${room.id}`"
            v-model="model"
            :disabled="!roomTask"
          />
        </template>

        <template v-if="model.step === `services_floors_type_${room.id}`">
          <div class="mb-20">
            <Headline
              >Welchen Boden wünschen Sie sich:
              <span class="font-light">{{
                getRoomNameWithIndex(room.name, getSortedIndex(room))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection :options="floors" v-model="floorType" />
            <SingleNavigation
              :step="`services_floors_quality_${room.id}`"
              :prev="`services_floors_task_${room.id}`"
              v-model="model"
              :disabled="!model.services.floors.change.roomConfigs[room.id].type"
            />
          </div>
        </template>

        <template v-if="model.step === `services_floors_quality_${room.id}`">
          <Headline
            >In welcher Qualität wünschen Sie sich Ihren neuen Boden:
            <span class="font-light">{{
              getRoomNameWithIndex(room.name, getSortedIndex(room))
            }}</span
            >?</Headline
          >
          <IconsOptionsSelection :options="qualities" v-model="floorChangeQuality" />
          <SingleNavigation
            :step="`services_floors_boards_${room.id}`"
            :prev="`services_floors_type_${room.id}`"
            v-model="model"
            :disabled="!model.services.floors.change.quality"
          />
        </template>

        <template v-if="model.step === `services_floors_boards_${room.id}`">
          <Headline
            >Sollen die Fussleisten auch erneuert werden:
            <span class="font-light">{{
              getRoomNameWithIndex(room.name, getSortedIndex(room))
            }}</span
            >?</Headline
          >
          <IconsOptionsSelection
            :options="yesNo"
            cols="md:grid-cols-2"
            v-model="selectedSkirtingBoardsComputed"
          />
          <SingleNavigation
            v-if="selectedSkirtingBoardsComputed && !isChange(room.id)"
            :step="`services_floors_perimeter_${room.id}`"
            :prev="`services_floors_task_${room.id}`"
            v-model="model"
          />
          <SingleNavigation
            v-else-if="!selectedSkirtingBoardsComputed && !isChange(room.id)"
            :step="`services_floors_old_${room.id}`"
            :prev="`services_floors_task_${room.id}`"
            v-model="model"
          />
          <SingleNavigation
            v-else-if="selectedSkirtingBoardsComputed && isChange(room.id)"
            :step="`services_floors_perimeter_${room.id}`"
            :prev="`services_floors_quality_${room.id}`"
            v-model="model"
          />
          <SingleNavigation
            v-else-if="!selectedSkirtingBoardsComputed && isChange(room.id)"
            :step="`services_floors_old_${room.id}`"
            :prev="`services_floors_quality_${room.id}`"
            v-model="model"
          />
        </template>

        <template v-if="model.step === `services_floors_perimeter_${room.id}`">
          <Headline
            >Wie viel Meter Fussleiste benötigen Sie für den folgenden Raum:
            <span class="font-light">{{
              getRoomNameWithIndex(room.name, getSortedIndex(room))
            }}</span
            >?</Headline
          >
          <div class="my-20 space-y-16">
            <Range
              v-model="room.areas.perimeter"
              :max="80"
              :formatter="(perimeter) => `${perimeter} m`"
            />
          </div>
          <SingleNavigation
            :step="`services_floors_boards_quality_${room.id}`"
            :prev="`services_floors_boards_${room.id}`"
            v-model="model"
            :disabled="!room.areas.floor"
          />
        </template>

        <template v-if="model.step === `services_floors_boards_quality_${room.id}`">
          <Headline
            >In welcher Qualität wünschen Sie sich die neuen Fussleisten:
            <span class="font-light">{{
              getRoomNameWithIndex(room.name, getSortedIndex(room))
            }}</span
            >?</Headline
          >
          <IconsOptionsSelection
            :options="qualities"
            v-model="model.services.floors.newSkirtingBoards.quality"
          />
          <SingleNavigation
            :step="`services_floors_old_${room.id}`"
            :prev="`services_floors_perimeter_${room.id}`"
            v-model="model"
            :disabled="!model.services.floors.newSkirtingBoards.quality"
          />
        </template>

        <template v-if="model.step === `services_floors_old_${room.id}`">
          <Headline
            >Soll alter Bodenbelag entfernt werden:
            <span class="font-light">{{
              getRoomNameWithIndex(room.name, getSortedIndex(room))
            }}</span
            >?</Headline
          >
          <IconsOptionsSelection
            :options="yesNo"
            cols="md:grid-cols-2"
            v-model="removeOldFloorComputed"
          />
          <SingleNavigation
            v-if="selectedSkirtingBoardsComputed"
            :step="
              index === storedModel.rooms.length - 1
                ? 'services_floors_final'
                : `services_floors_size_${storedModel.rooms[index + 1].id}`
            "
            :prev="`services_floors_boards_quality_${room.id}`"
            v-model="model"
          />
          <SingleNavigation
            v-else-if="!selectedSkirtingBoardsComputed"
            :step="
              index === storedModel.rooms.length - 1
                ? 'services_floors_final'
                : `services_floors_size_${storedModel.rooms[index + 1].id}`
            "
            :prev="`services_floors_boards_${room.id}`"
            v-model="model"
          />
        </template>
      </template>
    </form>
  </section>
</template>
<script setup>
import { z } from 'zod'
import { computed, onMounted, ref, watch } from 'vue'
import track from '@/utils/tracking.js'
import Headline from '@/components/Structure/Singles/Headline.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import createRoom from '@/utils/room'
import { useLocalStorage } from '@vueuse/core'
import Range from '@/components/Inputs/Singles/Range.vue'
import { getGclid } from '@/utils/gclid'
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import { perimeter } from '@/data/formulas/perimeter.js'
import {
  configTemplate,
  floors,
  qualities,
  roomTypes,
  types,
  yesNo
} from '@/data/options/v2/floor.js'
import { ContactValidationSchema } from '@/utils/validators.js'

const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const storedModel = useLocalStorage('floors_service_model', { ...configTemplate })

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  const baseSteps = ['services_floors_start', 'services_floors_final']

  const roomSteps = [
    'services_floors_size',
    'services_floors_task',
    'services_floors_type',
    'services_floors_quality',
    'services_floors_boards',
    'services_floors_perimeter',
    'services_floors_boards_quality',
    'services_floors_old'
  ]

  // Generate steps for each room
  const steps = baseSteps.concat(
    ...storedModel.value.rooms.flatMap((room) => roomSteps.map((step) => `${step}_${room.id}`))
  )

  // Find the current step index
  const currentStepIndex = steps.findIndex((step) => model.value.step === step)

  if (model.value.step === 'services_floors_final') {
    return 100
  }

  if (currentStepIndex === -1) return 0

  // Calculate progress percentage
  return (currentStepIndex / (steps.length - 1)) * 100
})

const roomTask = computed({
  get: () => {
    if (
      model.value.services.floors.change.roomConfigs[model.value.services.floors.change.currentRoom]
    ) {
      return 'change'
    }
    if (
      model.value.services.floors.recycleWoodenFloor.rooms.includes(
        model.value.services.floors.change.currentRoom
      )
    ) {
      return 'recycleWoodenFloor'
    }
  },
  set: (v) => {
    if (v === 'change') {
      model.value.services.floors.change.enabled = true
      if (
        model.value.services.floors.recycleWoodenFloor.rooms.includes(
          model.value.services.floors.change.currentRoom
        )
      ) {
        model.value.services.floors.recycleWoodenFloor.rooms =
          model.value.services.floors.recycleWoodenFloor.rooms.filter(
            (id) => id !== model.value.services.floors.change.currentRoom
          )
        storedModel.value.recycleWoodenFloor = storedModel.value.recycleWoodenFloor.filter(
          (id) => id !== model.value.services.floors.change.currentRoom
        )
      }
      if (
        !model.value.services.floors.change.roomConfigs[
          model.value.services.floors.change.currentRoom
        ]
      ) {
        model.value.services.floors.change.roomConfigs[
          model.value.services.floors.change.currentRoom
        ] = roomConfig.value
        storedModel.value.change.rooms[model.value.services.floors.change.currentRoom] =
          roomConfig.value
      }
    } else if (v === 'recycleWoodenFloor') {
      model.value.services.floors.recycleWoodenFloor.enabled = true
      if (
        model.value.services.floors.change.roomConfigs[
          model.value.services.floors.change.currentRoom
        ]
      ) {
        delete model.value.services.floors.change.roomConfigs[
          model.value.services.floors.change.currentRoom
        ]
        delete storedModel.value.change.rooms[model.value.services.floors.change.currentRoom]
      }
      if (
        !model.value.services.floors.recycleWoodenFloor.rooms[
          model.value.services.floors.change.currentRoom
        ]
      ) {
        model.value.services.floors.recycleWoodenFloor.rooms.push(
          model.value.services.floors.change.currentRoom
        )
        storedModel.value.recycleWoodenFloor.push(model.value.services.floors.change.currentRoom)
      }
    }
  }
})

const floorType = computed({
  get: () => {
    return model.value.services.floors.change.roomConfigs[
      model.value.services.floors.change.currentRoom
    ]
      ? model.value.services.floors.change.roomConfigs[
          model.value.services.floors.change.currentRoom
        ].type
      : ''
  },
  set: (v) => {
    model.value.services.floors.change.roomConfigs[
      model.value.services.floors.change.currentRoom
    ].type = v
    storedModel.value.change.rooms[model.value.services.floors.change.currentRoom].type = v
  }
})

const isChange = (roomId) => {
  if (model.value.services.floors.change.roomConfigs[roomId]) {
    return 'change'
  }
}

const isRecycleWoodenFloor = (roomId) => {
  if (model.value.services.floors.recycleWoodenFloor.rooms.includes(roomId)) {
    return 'recycleWoodenFloor'
  }
}

const floorChangeQuality = computed({
  get: () => {
    return storedModel.value.change.quality
  },
  set: (v) => {
    model.value.services.floors.change.quality = v
    storedModel.value.change.quality = v
  }
})

const selectedSkirtingBoardsComputed = computed({
  get: () => {
    return !!model.value.services.floors.newSkirtingBoards.rooms.includes(
      model.value.services.floors.change.currentRoom
    )
  },
  set: (v) => {
    if (v) {
      model.value.services.floors.newSkirtingBoards.enabled = true
      if (
        !model.value.services.floors.newSkirtingBoards.rooms.includes(
          model.value.services.floors.change.currentRoom
        )
      ) {
        model.value.services.floors.newSkirtingBoards.rooms.push(
          model.value.services.floors.change.currentRoom
        )
        storedModel.value.newSkirtingBoards.rooms.push(
          model.value.services.floors.change.currentRoom
        )
      }
    } else {
      if (
        model.value.services.floors.newSkirtingBoards.rooms.includes(
          model.value.services.floors.change.currentRoom
        )
      ) {
        model.value.services.floors.newSkirtingBoards.rooms =
          model.value.services.floors.newSkirtingBoards.rooms.filter(
            (id) => id !== model.value.services.floors.change.currentRoom
          )
        storedModel.value.newSkirtingBoards.rooms =
          storedModel.value.newSkirtingBoards.rooms.filter(
            (id) => id !== model.value.services.floors.change.currentRoom
          )
      }
    }
  }
})

const removeOldFloorComputed = computed({
  get: () => {
    return !!model.value.services.floors.removeOld.rooms.includes(
      model.value.services.floors.change.currentRoom
    )
  },
  set: (v) => {
    if (v) {
      model.value.services.floors.removeOld.enabled = true
      if (
        !model.value.services.floors.removeOld.rooms.includes(
          model.value.services.floors.change.currentRoom
        )
      ) {
        model.value.services.floors.removeOld.rooms.push(
          model.value.services.floors.change.currentRoom
        )
        storedModel.value.removeOld.rooms.push(model.value.services.floors.change.currentRoom)
      }
    } else {
      if (
        model.value.services.floors.removeOld.rooms.includes(
          model.value.services.floors.change.currentRoom
        )
      ) {
        model.value.services.floors.removeOld.rooms =
          model.value.services.floors.removeOld.rooms.filter(
            (id) => id !== model.value.services.floors.change.currentRoom
          )
        storedModel.value.removeOld.rooms = storedModel.value.removeOld.rooms.filter(
          (id) => id !== model.value.services.floors.change.currentRoom
        )
      }
    }
  }
})

const roomAmounts = ref({
  living_room: 0,
  kitchen: 0,
  bathroom: 0,
  utility_room: 0,
  cellar: 0,
  bedroom: 0
})

watch(
  roomAmounts,
  (newAmounts, oldAmounts) => {
    storedModel.value.roomAmounts = roomAmounts.value
  },
  { deep: true }
)

const hasSelectedRooms = computed(() => {
  return (
    storedModel.value.selectedRoomTypes.every((roomType) => roomAmounts.value[roomType] > 0) &&
    storedModel.value.selectedRoomTypes.length > 0
  )
})

const getNextStep = computed(() => {
  if (storedModel.value.rooms.length > 0) {
    return `services_floors_size_${storedModel.value.rooms[0].id}`
  }
  return 'services_floors_start'
})

watch(
  roomAmounts,
  (newAmounts, oldAmounts) => {
    Object.keys(newAmounts).forEach((roomType) => {
      updateRooms(roomType)
    })
  },
  { deep: true }
)

const selectedRoomTypesComputed = computed({
  get: () => storedModel.value.selectedRoomTypes || [],
  set: (v) => {
    // Determine which rooms have been deselected
    const deselectedRooms = storedModel.value.selectedRoomTypes.filter((room) => !v.includes(room))

    // Reset the amount for deselected rooms
    deselectedRooms.forEach((room) => {
      roomAmounts.value[room] = 0
    })

    // Update the stored model with the new selection
    storedModel.value.selectedRoomTypes = v
  }
})

const updateRooms = (roomType) => {
  const amount = roomAmounts.value[roomType]

  // Filter out rooms to ignore
  const roomsToIgnore = storedModel.value.toIgnoreRooms || []

  // Keep existing rooms of the current type, including ignored rooms
  const existingRooms = model.value.rooms.filter((room) => room.type === roomType)

  // Keep rooms of other types
  const otherRooms = model.value.rooms.filter((room) => room.type !== roomType)

  // Separate ignored rooms
  const ignoredRooms = existingRooms.filter((room) => roomsToIgnore.includes(room.id))
  let updatedRooms = existingRooms.filter((room) => !roomsToIgnore.includes(room.id))

  // Add new rooms if needed
  while (updatedRooms.length < amount) {
    // Use the original roomType for naming
    const roomName = getRoomName(roomType)

    // Create the room with original name but effective room type
    const newRoom = createRoom(roomName, roomType, false, true)
    updatedRooms.push(newRoom)
  }

  // Remove excess rooms if needed, but don't remove ignored rooms
  if (updatedRooms.length > amount) {
    updatedRooms = updatedRooms.slice(0, amount)
  }

  // Update the rooms array, including ignored rooms
  model.value.rooms = [...otherRooms, ...ignoredRooms, ...updatedRooms]

  // Synchronize storedModel.value.rooms with model.value.rooms
  if (!storedModel.value.rooms) {
    storedModel.value.rooms = []
  }

  // Remove rooms from storedModel that are no longer in model, except ignored rooms
  storedModel.value.rooms = storedModel.value.rooms.filter(
    (storedRoom) =>
      model.value.rooms.some((modelRoom) => modelRoom.id === storedRoom.id) &&
      !roomsToIgnore.includes(storedRoom.id)
  )

  //remove the rooms also from storedModel.value.change.rooms which is a object with objects in it
  Object.keys(storedModel.value.change.rooms).forEach((roomId) => {
    if (!model.value.rooms.some((room) => room.id === roomId) && !roomsToIgnore.includes(roomId)) {
      delete storedModel.value.change.rooms[roomId]
    }
  })

  // Add new rooms to storedModel or update existing ones, excluding ignored rooms
  model.value.rooms.forEach((modelRoom) => {
    if (!roomsToIgnore.includes(modelRoom.id)) {
      const existingStoredRoom = storedModel.value.rooms.find(
        (storedRoom) => storedRoom.id === modelRoom.id
      )
      if (existingStoredRoom) {
        // Update existing room, preserving its size
        Object.assign(existingStoredRoom, modelRoom, { areas: existingStoredRoom.areas })
      } else {
        // Add new room to localstorage
        storedModel.value.rooms.push({ ...modelRoom })
      }
    }
  })

  // Clean up roomConfigs, but keep configs for ignored rooms
  Object.keys(model.value.services.floors.change.roomConfigs).forEach((roomId) => {
    if (!model.value.rooms.some((room) => room.id === roomId) && !roomsToIgnore.includes(roomId)) {
      delete model.value.services.floors.change.roomConfigs[roomId]
    }
  })
}
const updateRoomSize = (roomId) => {
  if (!model.value?.rooms) {
    console.warn('model.rooms is undefined')
    return
  }

  const modelRoom = model.value.rooms.find((r) => r.id === roomId)
  if (!modelRoom) {
    console.warn(`Room with id ${roomId} not found in model`)
    return
  }

  if (!modelRoom.areas) {
    console.warn(`Room with id ${roomId} has no areas property`)
    return
  }

  modelRoom.areas.perimeter = perimeter(modelRoom.areas.floor)

  if (storedModel.value?.rooms) {
    const storedRoom = storedModel.value.rooms.find((r) => r.id === roomId)
    if (storedRoom && storedRoom.areas) {
      storedRoom.areas.floor = modelRoom.areas.floor
    } else {
      console.warn(`Room with id ${roomId} not found or has no areas property in storedModel`)
    }
  } else {
    console.warn('storedModel.rooms is undefined')
  }
}

const getRoomName = (roomType) => {
  const roomTypeObj = roomTypes.find((type) => type.value === roomType)
  return roomTypeObj ? roomTypeObj.label : 'Room'
}

const getRoomNameWithIndex = (label, overallIndex) => {
  // Find the room type object based on the label
  const roomTypeObj = roomTypes.find((room) => room.label === label)
  const roomName = roomTypeObj ? roomTypeObj.label : 'Room'

  // Filter rooms of the same type using the room's name (which should match the label)
  const roomsOfType = storedModel.value.rooms.filter((r) => r.name === label)
  const totalRoomsOfType = roomsOfType.length

  // Find the current room by its index in the sorted rooms array
  const currentRoom = sortedRooms.value[overallIndex]

  // Calculate the index of this room within its type group
  const roomTypeIndex = roomsOfType.findIndex((r) => r.id === currentRoom.id) + 1

  return totalRoomsOfType > 1 ? `${roomName} (${roomTypeIndex}/${totalRoomsOfType})` : roomName
}

const sortedRooms = computed(() => {
  return [...storedModel.value.rooms].sort((a, b) => {
    if (a.type < b.type) return -1
    if (a.type > b.type) return 1
    return 0
  })
})

const getSortedIndex = (room) => {
  return sortedRooms.value.findIndex((r) => r.id === room.id)
}

watch(storedModel.value.selectedRoomTypes, (newSelectedTypes) => {
  // Reset amount to 0 for deselected room types
  Object.keys(roomAmounts.value).forEach((roomType) => {
    if (!newSelectedTypes.includes(roomType)) {
      roomAmounts.value[roomType] = 0
      updateRooms(roomType)
    }
  })
})

const submitting = ref(false)
const submitted = ref(false)

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  model.value.contact.gclid = getGclid()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(props.modelValue)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'floor',
              content_name: 'floor'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: model.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('floor', data.volume / 100, 'EUR', data.id)
    })
    .finally(() => {
      submitting.value = false
    })
}

const room = useLocalStorage('form_floor_room', createRoom('Allgemein', 'living_room', false, true))
const roomConfig = ref({
  type: ''
})

watch(
  () => model.value.rooms,
  (newRooms) => {
    // Remove extra rooms from roomConfigs
    Object.keys(model.value.services.floors.change.roomConfigs).forEach((roomId) => {
      if (!newRooms.some((room) => room.id === roomId)) {
        delete model.value.services.floors.change.roomConfigs[roomId]
      }
    })
  },
  { deep: true }
)

watch(
  () => model.value.step,
  (v) => {
    const roomId = v.split('_').pop()
    if (roomId) {
      model.value.services.floors.change.currentRoom = roomId
    }
  }
)

onMounted(() => {
  const ignoredRoomIds = model.value.rooms
    .filter(
      (modelRoom) => !storedModel.value.rooms.some((storedRoom) => storedRoom.id === modelRoom.id)
    )
    .map((room) => room.id)

  // Update storedModel with the initial toIgnoreRooms
  storedModel.value.toIgnoreRooms = ignoredRoomIds

  if (
    !model.value.services.floors.change.roomConfigs ||
    Object.keys(model.value.services.floors.change.roomConfigs).length === 0
  ) {
    model.value.services.floors.change.roomConfigs = JSON.parse(
      JSON.stringify(storedModel.value.change.rooms)
    )
  }

  model.value.services.floors.change.currentRoom = ''

  if (storedModel.value.roomAmounts) {
    roomAmounts.value = storedModel.value.roomAmounts
  }

  roomTypes.forEach((type) => {
    if (roomAmounts.value[type.value] === undefined) {
      roomAmounts.value[type.value] = 0
    }
  })

  model.value.services.floors.change.currentRoom = ''

  if (storedModel.value.roomAmounts) {
    roomAmounts.value = storedModel.value.roomAmounts
  }

  roomTypes.forEach((type) => {
    if (roomAmounts.value[type.value] === undefined) {
      roomAmounts.value[type.value] = 0
    }
  })

  if (model.value.rooms.length > 0) {
    model.value.step = `services_floors_task_${model.value.rooms[0].id}`
  } else {
    model.value.step = 'services_floors_start'
  }

  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'floors',
        content_name: 'floors'
      }
    ]
  })
  track.tmViewItem('floors')
  model.value.setup = true
  model.value.step = 'services_floors_start'
  model.value.services.floors.enabled = true
})
</script>
