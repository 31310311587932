<template>
  <nav class="mt-10 flex items-center justify-center gap-5">
    <button
      class="btn btn--light px-10 py-3 text-xl"
      :disabled="
        deactivatePrev ||
        model.step === 'start' ||
        model.step.includes('start') ||
        model.step === 'types'
      "
      @click="prev"
    >
      zurück
    </button>
    <button
      class="btn btn--primary px-10 py-3 text-xl"
      :disabled="deactivateNext || disabled"
      @click="next"
    >
      weiter
    </button>
  </nav>
</template>

<script setup>
import { computed, onMounted } from 'vue'

const props = defineProps({
  modelValue: {},
  prev: String,
  step: String,
  disabled: Boolean,
  deactivatePrev: Boolean,
  deactivateNext: Boolean
})

const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const prev = () => {
  model.value.step = props.prev
}

const next = () => {
  model.value.step = props.step
}
</script>
