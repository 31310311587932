import { createApp } from 'vue'
import { createPinia } from 'pinia'
import mitt from 'mitt'

import './assets/main.css'
import 'floating-vue/dist/style.css'
import * as Sentry from '@sentry/vue'

import { vTooltip } from 'floating-vue'
import { registerGclid } from '@/utils/gclid'

// Function to bootstrap and mount the Vue.js application
export function bootstrap(App: any, target: any, attributes: any, withIntegration: boolean = true) {
  registerGclid()

  // Create a new Vue application instance
  // second argument is object of attributes that will be
  // converted to properites in App.vue
  const emitter = mitt()

  const app = createApp(App, attributes)

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN || '',
    integrations: withIntegration
      ? [(Sentry.browserTracingIntegration(), Sentry.replayIntegration())]
      : [],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  app.use(createPinia())
  app.directive('tooltip', vTooltip)
  app.directive('focus', {
    mounted: (el) => el.focus()
  })
  app.config.globalProperties.emitter = emitter

  // Mount the Vue application onto the specified target element
  app.mount(target)
}
