function getParam(p: string) {
  var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

function getExpiryRecord(value: string) {
  var expiryPeriod = 90 * 24 * 60 * 60 * 1000 // 90 day expiry in milliseconds

  var expiryDate = new Date().getTime() + expiryPeriod
  return {
    value: value,
    expiryDate: expiryDate
  }
}

function addGclid() {
  var gclidParam = getParam('gclid')
  var gclidRecord = null

  var gclsrcParam = getParam('gclsrc')
  var isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1

  if (gclidParam && isGclsrcValid) {
    gclidRecord = getExpiryRecord(gclidParam)
    localStorage.setItem('gclid', JSON.stringify(gclidRecord))
  }
}

export const getGclid = () => {
  if (typeof localStorage === 'undefined') {
    return null
  }

  const gclid = localStorage.getItem('gclid')

  if (!gclid) {
    return null
  }

  const gclidRecord = JSON.parse(gclid)

  return gclidRecord && gclidRecord.value
}

export const registerGclid = () => {
  addGclid()

  window.addEventListener('load', addGclid)
}

export default { getGclid, registerGclid }
