<template>
  <!-- TrustBox widget - Review Collector -->
  <div
    id="trustpilot-widget-container"
    class="trustpilot-widget"
    data-locale="de-DE"
    data-template-id="56278e9abfbbba0bdcd568bc"
    data-businessunit-id="66cc61e26557657d6b43a56b"
    data-style-height="52px"
    data-style-width="100%"
  >
    <a href="https://de.trustpilot.com/review/moreathome.de" target="_blank" rel="noopener">Trustpilot</a>
  </div>
  <!-- End TrustBox widget -->
</template>

<script>
import { onMounted } from 'vue'

export default {
  name: 'TrustPilotStatic',
  setup() {
    onMounted(() => {
      if (typeof window !== 'undefined' && window.Trustpilot) {
        window.Trustpilot.loadFromElement(document.getElementById('trustpilot-widget-container'))
      }
    })
  }
}
</script>