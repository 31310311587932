<template xmlns="http://www.w3.org/1999/html">
  <section>
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-5 sm:mb-10">
        <p class="text-center text-3xl font-bold">{{ headlineText }}</p>
      </div>
      <div class="mx-auto mb-8">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <template v-if="model.step === 'services_doors_start'">
        <Headline>Möchten Sie neue Innentüren?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="yesNo"
          v-model="model.services.doors.renew.enabled"
        />
        <SingleNavigation
          v-if="model.services.doors.renew.enabled"
          step="services_doors_renew_count"
          v-model="model"
        />
        <SingleNavigation v-else step="services_doors_lack" v-model="model" />
      </template>

      <template v-if="model.step === 'services_doors_renew_count'">
        <Headline>Wie viele Türen sollen erneuert werden?</Headline>
        <div class="mx-auto mt-14">
          <div class="mb-4 flex flex-row items-center justify-between">
            <span class="text-xl font-semibold">Anzahl der Türen:</span>
            <CounterInput v-model="model.services.doors.renew.amount" />
          </div>
        </div>
        <SingleNavigation
          step="services_doors_renew_quality"
          prev="services_doors_start"
          v-model="model"
          :disabled="!model.services.doors.renew.amount"
        />
      </template>

      <template v-if="model.step === 'services_doors_renew_quality'">
        <Headline>In welcher Qualität wünschen Sie sich die neuen Türen?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-3"
          :options="qualities"
          v-model="model.services.doors.renew.quality"
        />
        <SingleNavigation
          step="services_doors_lack"
          prev="services_doors_renew_count"
          v-model="model"
        />
      </template>

      <template v-if="model.step === 'services_doors_lack'">
        <Headline>Sollen bestehende Türen lackiert und aufbereitet werden?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="yesNo"
          v-model="model.services.doors.lack.enabled"
        />
        <SingleNavigation
          v-if="model.services.doors.lack.enabled && !model.services.doors.renew.enabled"
          step="services_doors_lack_count"
          prev="services_doors_start"
          v-model="model"
        />
        <SingleNavigation
          v-else-if="model.services.doors.lack.enabled && model.services.doors.renew.enabled"
          step="services_doors_lack_count"
          prev="services_doors_renew_quality"
          v-model="model"
        />
        <SingleNavigation
          v-else-if="!model.services.doors.lack.enabled && !model.services.doors.renew.enabled"
          step="services_doors_renew_entranceDoor"
          prev="services_doors_start"
          v-model="model"
        />
        <SingleNavigation
          v-else-if="!model.services.doors.lack.enabled && model.services.doors.renew.enabled"
          step="services_doors_renew_entranceDoor"
          prev="services_doors_renew_quality"
          v-model="model"
        />
      </template>

      <template v-if="model.step === 'services_doors_lack_count'">
        <Headline>Wie viele Türen sollen lackiert werden?</Headline>
        <div class="mx-auto mt-14">
          <div class="mb-4 flex flex-row items-center justify-between">
            <span class="text-xl font-semibold">Anzahl der Türen:</span>
            <CounterInput v-model="model.services.doors.lack.amount" />
          </div>
        </div>
        <SingleNavigation
          step="services_doors_renew_entranceDoor"
          prev="services_doors_lack"
          v-model="model"
          :disabled="!model.services.doors.lack.amount"
        />
      </template>

      <template v-if="model.step === 'services_doors_renew_entranceDoor'">
        <Headline>Möchten Sie eine neue Eingangstür?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="yesNo"
          v-model="model.services.doors.entranceDoor.enabled"
        />
        <SingleNavigation
          v-if="model.services.doors.entranceDoor.enabled && model.services.doors.lack.enabled"
          step="services_doors_entranceDoor_quality"
          prev="services_doors_lack_count"
          v-model="model"
        />
        <SingleNavigation
          v-else-if="
            model.services.doors.entranceDoor.enabled && !model.services.doors.lack.enabled
          "
          step="services_doors_entranceDoor_quality"
          prev="services_doors_lack"
          v-model="model"
        />
        <SingleNavigation
          v-else-if="
            !model.services.doors.entranceDoor.enabled && model.services.doors.lack.enabled
          "
          step="services_doors_final"
          prev="services_doors_lack_count"
          v-model="model"
        />
        <SingleNavigation
          v-else
          step="services_doors_final"
          prev="services_doors_lack"
          v-model="model"
        />
      </template>

      <template v-if="model.step === 'services_doors_entranceDoor_quality'">
        <Headline>In welcher Qualität wünschen Sie sich die neue Eingangstür?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-3"
          :options="qualities"
          v-model="model.services.doors.entranceDoor.quality"
        />
        <SingleNavigation
          step="services_doors_final"
          prev="services_doors_renew_entranceDoor"
          v-model="model"
        />
      </template>
    </form>
  </section>
</template>

<script setup>
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import Headline from '@/components/Structure/Singles/Headline.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import { computed, onMounted } from 'vue'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import track from '@/utils/tracking.js'
import { yesNo, qualities } from '@/data/options/v2/doors.js'
import { ContactValidationSchema } from '@/utils/validators.js'

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  switch (model.value.step) {
    case '"services_doors_start"':
      return 0
    case 'services_doors_renew_count':
      return 10
    case 'services_doors_renew_quality':
      return 20
    case 'services_doors_lack':
      return 30
    case 'services_doors_lack_count':
      return 40
    case 'services_doors_renew_entranceDoor':
      return 50
    case 'services_doors_base_type':
      return 60
    case 'services_doors_entranceDoor_quality':
      return 80
    case 'services_doors_final':
      return 100
    default:
      return 0
  }
})

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  model.value.contact.gclid = getGclid()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(props.modelValue)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'doors',
              content_name: 'doors'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: model.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('doors', data.volume / 100, 'EUR', data.id)
    })
    .finally(() => {
      submitting.value = false
    })
}

onMounted(() => {
  model.value.step = 'services_doors_start'
})
</script>
