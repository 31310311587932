<script setup>
import { computed } from 'vue'

const emit = defineEmits(['update:modelValue', 'next'])

const props = defineProps({
  modelValue: {},
  name: {
    type: String,
    default: ''
  },
  cols: {
    type: String,
    default: 'md:grid-cols-3'
  },
  mobileCold: {
    type: String,
    default: 'grid-cols-2'
  },
  multiple: {
    type: Boolean,
    default: false
  },
  options: Array
})

const setActive = (option) => {
  if (props.multiple) {
    if (model.value.includes(option.value)) {
      model.value = model.value.filter((v) => v !== option.value)
    } else {
      model.value = [...model.value, option.value]
    }

    return
  }

  if (model.value === option.value && props.canClear) {
    model.value = ''
  } else {
    model.value = option.value
  }
}

const isActive = (value) => {
  if (model.value === undefined || model.value === null) {
    return false // or any default value you prefer
  }

  return props.multiple
    ? Array.isArray(model.value) && model.value.includes(value)
    : model.value === value
}

const model = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
    emit('next')
  }
})
</script>
<template>
  <fieldset class="grid gap-4 md:gap-7" :name="name" :class="[cols, mobileCold]">
    <div v-for="(option, k) in options" :key="k" class="relative flex flex-col items-center">
      <div class="w-full">
        <button
          class="!md:hover:text-white block w-full rounded-lg border-2 border-primary fill-primary text-primary md:hover:bg-primary md:hover:!fill-white lg:border-4"
          type="button"
          :class="{
            '!border-action bg-primary !fill-white !text-white': isActive(option.value)
          }"
          @click="() => setActive(option)"
        >
          <div class="relative flex flex-col items-center justify-center p-3 md:p-5">
            <component
              :alt="option.label"
              :is="option.image"
              class="h-20 w-auto object-contain object-center sm:h-24 lg:h-32"
            />
          </div>
        </button>
        <div class="md:block">
          <label
            class="text-md sm:text-md mt-2 block text-center font-semibold leading-tight sm:leading-snug lg:text-lg lg:leading-normal"
          >
            {{ option.label }}
          </label>
        </div>
      </div>
      <slot name="option-slot" :option="option" :isActive="isActive(option.value)" />
    </div>
  </fieldset>
</template>
