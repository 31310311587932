<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="() => (isOpen = false)" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25 backdrop-blur-sm" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle as="h3" class="text-2xl font-medium leading-6">
                Willkommen im Sanierungsrechner!
              </DialogTitle>
              <div class="prose mt-5">
                <p>
                  Ihrer Kreativität sind keine Grenzen gesetzt, bitte beachten Sie aber, dass es
                  sich bei den angezeigten Sanierungskosten um eine Schätzung handelt. Abweichungen
                  nach unten und nach oben sind möglich, da sich die tatsächlichen Kosten je nach
                  Umfang der Arbeiten unterscheiden können. Die Preise beinhalten sowohl Material-
                  als auch Lohnkosten.
                </p>
                <p>
                  Bei den hinterlegten Produkten handelt es sich um Beispielprodukte, welche den
                  preislichen Rahmen widerspiegeln sollen. Gerne können Sie zusätzliche Leistungen
                  oder individuelle Wünsche mit den Handwerkern direkt besprechen.
                </p>
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  class="w-full rounded-lg bg-primary py-2 font-bold text-white"
                  @click="finish"
                >
                  Los geht's!
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { useLocalStorage } from '@vueuse/core'

const isOpen = useLocalStorage('startInfo', true)

function finish() {
  isOpen.value = false
}
</script>
