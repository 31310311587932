import { ref } from 'vue'

import GasThermal from '@public/images/calc/heating/v2/gas-thermal.svg'
import OilPlastic from '@public/images/calc/heating/v2/oil-plastic.svg'
import OilSteel from '@public/images/calc/heating/v2/oil-steel.svg'
import OilGround from '@public/images/calc/heating/v2/oil-ground.svg'
import No from '@public/images/no.svg'
import Yes from '@public/images/yes.svg'
import Pellet from '@public/images/calc/heating/v2/pellet.svg'
import HeatPump from '@public/images/calc/heating/v2/heat_pump.svg'
import TowelRadiatorCompact from '@public/images/calc/heating/v2/towel_radiator_compact.svg'
import FlatRadiatorPlan from '@public/images/calc/heating/v2/flat_radiator_plan.svg'
import TowelKompakt from '@public/images/calc/heating/v2/towel_kompakt.svg'
import FlatRadiatorPlanWall from '@public/images/calc/heating/v2/flat_radiator_plan_wall.svg'

export const rebuildOptions = [
  {
    label: 'Heizungsanlage',
    value: 'gas',
    image: GasThermal
  },
  {
    label: 'Öltank - Kunststoff',
    value: 'oil-plastic',
    image: OilPlastic
  },
  {
    label: 'Öltank - Stahl',
    value: 'oil-steel',
    image: OilSteel
  },
  {
    label: 'Erdöltank',
    value: 'oil-ground',
    image: OilGround
  },
  {
    label: 'Nein',
    value: 'no',
    image: No
  }
]

export const yesNo = [
  {
    label: 'Ja',
    value: true,
    image: Yes
  },
  {
    label: 'Nein',
    value: false,
    image: No
  }
]

export const yesNoString = [
  {
    label: 'Ja',
    value: 'yes',
    image: Yes
  },
  {
    label: 'Nein',
    value: 'no',
    image: No
  }
]

export const heatingOptions = [
  {
    label: 'Pelletheizung',
    value: 'pellet',
    image: Pellet
  },
  {
    label: 'Gas - Zentralheizung',
    value: 'gas-thermal',
    image: GasThermal
  },
  {
    label: 'Luftwärmepumpe',
    value: 'air-outer',
    image: HeatPump
  },
  {
    label: 'Nein',
    value: 'no',
    image: No
  }
]

export const radiatorOptions = ref([
  {
    id: 1,
    label: 'Standardheizkörper (profiliert)',
    value: 'compact_flat_radiator',
    amount: 0,
    image: TowelRadiatorCompact
  },
  {
    id: 2,
    label: 'Planheizkörper',
    value: 'flat_radiator_plan',
    amount: 0,
    image: FlatRadiatorPlan
  },
  {
    id: 3,
    label: 'Handtuchheizkörper',
    value: 'towel_radiator_compact',
    amount: 0,
    image: TowelKompakt
  },
  {
    id: 4,
    label: 'Vertikalheizkörper (plan)',
    value: 'wall_convector',
    amount: 0,
    image: FlatRadiatorPlanWall
  }
])
