<template>
  <section>
    <form @submit.prevent="submitHandler" id="form">
      <div class="flex flex-col gap-6 md:gap-10">
        <div class="flex flex-col gap-1 md:gap-5">
          <Headline>{{ headlineText }}</Headline>
          <ProgressBar :progress="currentProgress" class="" />
        </div>
        <div class="mt-sm flex flex-col gap-md">
          <template v-if="model.step === 'services_wallsCeilings_start'">
            <Headline>Welche Zimmer sollen bearbeitet werden?</Headline>
            <IconsOptionsSelection
              :options="roomTypes"
              cols="md:grid-cols-3"
              v-model="selectedRoomTypesComputed"
              multiple
            >
              <template #option-slot="{ option, isActive }">
                <div v-if="isActive" class="mt-3 flex w-full flex-row items-center justify-between">
                  <span class="font-semibold">Anzahl:</span>
                  <CounterInput v-model="roomAmounts[option.value]" />
                </div>
              </template>
            </IconsOptionsSelection>
            <SingleNavigation :step="getNextStep" v-model="model" :disabled="!hasSelectedRooms" />
          </template>
          <template v-for="(room, index) in storedModel.rooms" :key="room.id">
            <template v-if="model.step === `services_wallsCeilings_size_${room.id}`">
              <Headline
                >Wie groß ist der folgende Raum:
                <span class="font-light">{{
                  getRoomNameWithIndex(room.name, getSortedIndex(room))
                }}</span
                >?</Headline
              >
              <Range
                v-model="room.areas.floor"
                @update:modelValue="updateRoomSize(room.id)"
                :max="100"
              />
              <SingleNavigation
                :step="`services_wallsCeilings_change_${room.id}`"
                :prev="
                  index === 0
                    ? 'services_wallsCeilings_start'
                    : `services_wallsCeilings_removeOld_${model.rooms[index - 1].id}`
                "
                v-model="model"
                :disabled="!room.areas.floor"
              />
            </template>

            <template v-if="model.step === `services_wallsCeilings_change_${room.id}`">
              <Headline
                >Möchten Sie Ihre Wände bearbeiten:
                <span class="font-light">{{
                  getRoomNameWithIndex(room.name, getSortedIndex(room))
                }}</span
                >?</Headline
              >
              <IconsOptionsSelection
                :options="yesNo"
                cols="md:grid-cols-2"
                v-model="changeComputed"
              />
              <SingleNavigation
                v-if="changeComputed"
                :step="`services_wallsCeilings_change_type_${room.id}`"
                :prev="`services_wallsCeilings_size_${room.id}`"
                v-model="model"
              />
              <SingleNavigation
                v-else
                :step="`services_wallsCeilings_changeCeiling_${room.id}`"
                :prev="`services_wallsCeilings_size_${room.id}`"
                v-model="model"
              />
            </template>

            <template v-if="model.step === `services_wallsCeilings_change_type_${room.id}`">
              <Headline
                >Wie sollen die Wände bearbeitet werden:
                <span class="font-light">{{
                  getRoomNameWithIndex(room.name, getSortedIndex(room))
                }}</span
                >?</Headline
              >
              <IconsOptionsSelection
                :options="wallsOptions"
                cols="md:grid-cols-2"
                v-model="changeTypeComputed"
              />
              <SingleNavigation
                :step="`services_wallsCeilings_change_quality_${room.id}`"
                :prev="`services_wallsCeilings_change_${room.id}`"
                v-model="model"
                :disabled="!changeTypeComputed"
              />
            </template>

            <template v-if="model.step === `services_wallsCeilings_change_quality_${room.id}`">
              <Headline
                >In welcher Qualität wünschen Sie sich die neue/n Farbe/Fliesen:
                <span class="font-light">{{
                  getRoomNameWithIndex(room.name, getSortedIndex(room))
                }}</span
                >?</Headline
              >
              <IconsOptionsSelection
                :options="qualities"
                cols="md:grid-cols-3"
                v-model="model.services.wallsCeilings.change.quality"
              />
              <SingleNavigation
                :step="`services_wallsCeilings_changeCeiling_${room.id}`"
                :prev="`services_wallsCeilings_change_type_${room.id}`"
                v-model="model"
                :disabled="!changeComputed"
              />
            </template>

            <template v-if="model.step === `services_wallsCeilings_changeCeiling_${room.id}`">
              <Headline
                >Sollen die Decken bearbeitet werden:
                <span class="font-light">{{
                  getRoomNameWithIndex(room.name, getSortedIndex(room))
                }}</span
                >?</Headline
              >
              <IconsOptionsSelection
                :options="yesNo"
                cols="md:grid-cols-2"
                v-model="changeCeilingComputed"
              />
              <SingleNavigation
                v-if="changeComputed && changeCeilingComputed"
                :step="`services_wallsCeilings_changeCeiling_type_${room.id}`"
                :prev="`services_wallsCeilings_change_type_${room.id}`"
                v-model="model"
              />
              <SingleNavigation
                v-else-if="!changeComputed && changeCeilingComputed"
                :step="`services_wallsCeilings_changeCeiling_type_${room.id}`"
                :prev="`services_wallsCeilings_change_${room.id}`"
                v-model="model"
              />
              <SingleNavigation
                v-else-if="changeComputed && !changeCeilingComputed"
                :step="`services_wallsCeilings_removeOld_${room.id}`"
                :prev="`services_wallsCeilings_change_quality_${room.id}`"
                v-model="model"
              />
              <SingleNavigation
                v-else-if="!changeComputed && !changeCeilingComputed"
                :step="`services_wallsCeilings_removeOld_${room.id}`"
                :prev="`services_wallsCeilings_change_${room.id}`"
                v-model="model"
              />
            </template>
            <template v-if="model.step === `services_wallsCeilings_changeCeiling_type_${room.id}`">
              <Headline
                >Wie sollen die Decken bearbeitet werden:
                <span class="font-light">{{
                  getRoomNameWithIndex(room.name, getSortedIndex(room))
                }}</span
                >?</Headline
              >
              <IconsOptionsSelection
                :options="wallsCeilingsOptions"
                cols="md:grid-cols-3"
                v-model="changeCeilingType"
              />
              <SingleNavigation
                :step="`services_wallsCeilings_changeCeiling_quality_${room.id}`"
                :prev="`services_wallsCeilings_changeCeiling_${room.id}`"
                v-model="model"
                :disabled="!changeCeilingType"
              />
            </template>
            <template
              v-if="model.step === `services_wallsCeilings_changeCeiling_quality_${room.id}`"
            >
              <Headline
                >In welcher Qualität wünschen Sie sich die Farbe:
                <span class="font-light">{{
                  getRoomNameWithIndex(room.name, getSortedIndex(room))
                }}</span
                >?</Headline
              >
              <IconsOptionsSelection
                :options="qualities"
                cols="md:grid-cols-3"
                v-model="model.services.wallsCeilings.changeCeiling.quality"
              />
              <SingleNavigation
                v-if="changeCeilingType === 'all'"
                :step="`services_wallsCeilings_ceilingSpots_${room.id}`"
                :prev="`services_wallsCeilings_changeCeiling_type_${room.id}`"
                v-model="model"
                :disabled="!model.services.wallsCeilings.changeCeiling.quality"
              />
              <SingleNavigation
                v-else
                :step="`services_wallsCeilings_removeOld_${room.id}`"
                :prev="`services_wallsCeilings_changeCeiling_type_${room.id}`"
                v-model="model"
                :disabled="!model.services.wallsCeilings.changeCeiling.quality"
              />
            </template>

            <template v-if="model.step === `services_wallsCeilings_ceilingSpots_${room.id}`">
              <Headline
                >Sollen Deckenspots verbaut werden:
                <span class="font-light">{{
                  getRoomNameWithIndex(room.name, getSortedIndex(room))
                }}</span
                >?</Headline
              >
              <IconsOptionsSelection
                :options="yesNo"
                cols="md:grid-cols-2"
                v-model="ceilingSpots"
              />
              <SingleNavigation
                v-if="changeCeilingComputed"
                :step="`services_wallsCeilings_removeOld_${room.id}`"
                :prev="`services_wallsCeilings_changeCeiling_quality_${room.id}`"
                v-model="model"
              />
              <SingleNavigation
                v-else
                :step="`services_wallsCeilings_removeOld_${room.id}`"
                :prev="`services_wallsCeilings_changeCeiling_${room.id}`"
                v-model="model"
              />
            </template>

            <template v-if="model.step === `services_wallsCeilings_removeOld_${room.id}`">
              <Headline
                >Soll alte Tapete entfernt werden:
                <span class="font-light">{{
                  getRoomNameWithIndex(room.name, getSortedIndex(room))
                }}</span
                >?</Headline
              >
              <IconsOptionsSelection :options="yesNo" cols="md:grid-cols-2" v-model="removeOld" />
              <SingleNavigation
                v-if="changeCeilingType === 'all'"
                :step="
                  index === storedModel.rooms.length - 1
                    ? 'services_wallsCeilings_final'
                    : `services_wallsCeilings_size_${storedModel.rooms[index + 1].id}`
                "
                :prev="`services_wallsCeilings_ceilingSpots_${room.id}`"
                v-model="model"
              />
              <SingleNavigation
                v-else-if="changeCeilingComputed !== 'all' && changeCeilingComputed"
                :step="
                  index === storedModel.rooms.length - 1
                    ? 'services_wallsCeilings_final'
                    : `services_wallsCeilings_size_${storedModel.rooms[index + 1].id}`
                "
                :prev="`services_wallsCeilings_change_quality_${room.id}`"
                v-model="model"
              />
              <SingleNavigation
                v-else-if="!changeCeilingComputed"
                :step="
                  index === storedModel.rooms.length - 1
                    ? 'services_wallsCeilings_final'
                    : `services_wallsCeilings_size_${storedModel.rooms[index + 1].id}`
                "
                :prev="`services_wallsCeilings_changeCeiling_${room.id}`"
                v-model="model"
              />
            </template>
          </template>
        </div>
      </div>
    </form>
  </section>
</template>

<script setup>
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import Headline from '@/components/Structure/Singles/Headline.vue'
import track from '@/utils/tracking.js'
import { computed, onMounted, ref, watch } from 'vue'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import { useLocalStorage } from '@vueuse/core'
import { configTemplate } from '@/data/options/v2/walls.js'
import createRoom from '@/utils/room.js'
import {
  yesNo,
  wallsOptions,
  qualities,
  wallsCeilingsOptions,
  roomTypes
} from '@/data/options/v2/walls.js'
import Range from '@/components/Inputs/Singles/Range.vue'
import { ContactValidationSchema } from '@/utils/validators.js'
const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const storedModel = useLocalStorage('wallsCeilings_service_model', { ...configTemplate })

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  const baseSteps = ['services_wallsCeilings_start', 'services_wallsCeilings_final']

  const roomSteps = [
    'services_wallsCeilings_size',
    'services_wallsCeilings_change',
    'services_wallsCeilings_change_type',
    'services_wallsCeilings_change_quality',
    'services_wallsCeilings_changeCeiling',
    'services_wallsCeilings_changeCeiling_type',
    'services_wallsCeilings_changeCeiling_quality',
    'services_wallsCeilings_ceilingSpots',
    'services_wallsCeilings_removeOld'
  ]

  // Generate steps for each room
  const steps = baseSteps.concat(
    ...storedModel.value.rooms.flatMap((room) => roomSteps.map((step) => `${step}_${room.id}`))
  )

  // Find the current step index
  const currentStepIndex = steps.findIndex((step) => model.value.step === step)

  if (currentStepIndex === -1) return 0

  if (model.value.step === 'services_wallsCeilings_final') {
    return 100
  }

  // Calculate progress percentage
  return (currentStepIndex / (steps.length - 1)) * 100
})

const getNextStep = computed(() => {
  if (storedModel.value.rooms.length > 0) {
    return `services_wallsCeilings_size_${storedModel.value.rooms[0].id}`
  }
  return 'services_wallsCeilings_start'
})

watch(
  () => model.value.rooms,
  (newRooms) => {
    // Remove extra rooms from roomConfigs
    Object.keys(model.value.services.wallsCeilings.change.roomConfigs).forEach((roomId) => {
      if (!newRooms.some((room) => room.id === roomId)) {
        delete model.value.services.wallsCeilings.change.roomConfigs[roomId]
      }
    })
  },
  { deep: true }
)

const changeComputed = computed({
  get: () => {
    return !!model.value.services.wallsCeilings.change.roomConfigs[
      model.value.services.wallsCeilings.change.currentRoom
    ]
  },
  set: (v) => {
    if (v) {
      model.value.services.wallsCeilings.change.enabled = true
      model.value.services.wallsCeilings.change.roomConfigs[
        model.value.services.wallsCeilings.change.currentRoom
      ] = {
        type: ''
      }
    } else {
      delete model.value.services.wallsCeilings.change.roomConfigs[
        model.value.services.wallsCeilings.change.currentRoom
      ]
    }
  }
})

const changeTypeComputed = computed({
  get: () => {
    try {
      const wallsCeilings = model.value?.services?.wallsCeilings
      const change = wallsCeilings?.change
      const currentRoom = change?.currentRoom
      const roomConfigs = change?.roomConfigs

      if (currentRoom && roomConfigs && roomConfigs[currentRoom]) {
        return roomConfigs[currentRoom].type || ''
      }
      return ''
    } catch (error) {
      console.error('Error in changeTypeComputed getter:', error)
      return ''
    }
  },
  set: (v) => {
    try {
      const wallsCeilings = model.value?.services?.wallsCeilings
      const change = wallsCeilings?.change
      const currentRoom = change?.currentRoom
      const roomConfigs = change?.roomConfigs

      if (currentRoom && roomConfigs) {
        if (!roomConfigs[currentRoom]) {
          roomConfigs[currentRoom] = {}
        }
        roomConfigs[currentRoom].type = v
      } else {
        console.warn('Unable to set type: Invalid path to roomConfigs or currentRoom')
      }
    } catch (error) {
      console.error('Error in changeTypeComputed setter:', error)
    }
  }
})

const updateRoomSize = (roomId) => {
  if (storedModel.value.rooms) {
    const room = storedModel.value.rooms.find((r) => r.id === roomId)
    if (room) {
      const modelRoom = model.value.rooms.find((r) => r.id === roomId)
      modelRoom.areas.walls = modelRoom.areas.floor * 2.5
      modelRoom.areas.ceiling = modelRoom.areas.floor

      // Update only the necessary properties
      room.areas.floor = modelRoom.areas.floor
      room.areas.walls = modelRoom.areas.walls
      room.areas.ceiling = modelRoom.areas.ceiling
    }
  }
}

const changeCeilingComputed = computed({
  get: () => {
    return !!model.value.services.wallsCeilings.changeCeiling.roomConfigs[
      model.value.services.wallsCeilings.change.currentRoom
    ]
  },
  set: (v) => {
    if (v) {
      model.value.services.wallsCeilings.changeCeiling.enabled = true
      model.value.services.wallsCeilings.changeCeiling.roomConfigs[
        model.value.services.wallsCeilings.change.currentRoom
      ] = {
        type: ''
      }
    } else {
      delete model.value.services.wallsCeilings.changeCeiling.roomConfigs[
        model.value.services.wallsCeilings.change.currentRoom
      ]
    }
  }
})

const changeCeilingType = computed({
  get: () =>
    model.value.services.wallsCeilings.changeCeiling.roomConfigs[
      model.value.services.wallsCeilings.change.currentRoom
    ]?.type || '',
  set: (v) => {
    model.value.services.wallsCeilings.changeCeiling.roomConfigs[
      model.value.services.wallsCeilings.change.currentRoom
    ].type = v
  }
})

const ceilingSpots = computed({
  get: () => {
    return !!model.value.services.wallsCeilings.ceilingSpots.rooms.includes(
      model.value.services.wallsCeilings.change.currentRoom
    )
  },
  set: (v) => {
    if (v) {
      model.value.services.wallsCeilings.ceilingSpots.enabled = true
      model.value.services.wallsCeilings.ceilingSpots.rooms.push(
        model.value.services.wallsCeilings.change.currentRoom
      )
    } else {
      model.value.services.wallsCeilings.ceilingSpots.rooms =
        model.value.services.wallsCeilings.ceilingSpots.rooms.filter(
          (room) => room !== model.value.services.wallsCeilings.change.currentRoom
        )
    }
  }
})

const removeOld = computed({
  get: () => {
    return !!model.value.services.wallsCeilings.removeOld.rooms.includes(
      model.value.services.wallsCeilings.change.currentRoom
    )
  },
  set: (v) => {
    if (v) {
      model.value.services.wallsCeilings.removeOld.enabled = true
      model.value.services.wallsCeilings.removeOld.rooms.push(
        model.value.services.wallsCeilings.change.currentRoom
      )
    } else {
      model.value.services.wallsCeilings.removeOld.rooms =
        model.value.services.wallsCeilings.removeOld.rooms.filter(
          (room) => room !== model.value.services.wallsCeilings.change.currentRoom
        )
    }
  }
})

const selectedRoomTypesComputed = computed({
  get: () => storedModel.value.selectedRoomTypes || [],
  set: (v) => {
    // Determine which rooms have been deselected
    const deselectedRooms = storedModel.value.selectedRoomTypes.filter((room) => !v.includes(room))

    // Reset the amount for deselected rooms
    deselectedRooms.forEach((room) => {
      roomAmounts.value[room] = 0
    })

    // Update the stored model with the new selection
    storedModel.value.selectedRoomTypes = v
  }
})

const roomAmounts = ref({
  living_room: 0,
  kitchen: 0,
  bathroom: 0,
  utility_room: 0,
  cellar: 0,
  bedroom: 0
})

watch(
  roomAmounts,
  (newAmounts, oldAmounts) => {
    storedModel.value.roomAmounts = roomAmounts.value
  },
  { deep: true }
)

watch(
  () => model.value.step,
  (v) => {
    const roomId = v.split('_').pop()
    if (roomId) {
      model.value.services.wallsCeilings.change.currentRoom = roomId
    }
  }
)

const getRoomName = (roomType) => {
  const roomTypeObj = roomTypes.find((type) => type.value === roomType)
  return roomTypeObj ? roomTypeObj.label : 'Room'
}

const getRoomNameWithIndex = (label, overallIndex) => {
  // Find the room type object based on the label
  const roomTypeObj = roomTypes.find((room) => room.label === label)
  const roomName = roomTypeObj ? roomTypeObj.label : 'Room'

  // Filter rooms of the same type using the room's name (which should match the label)
  const roomsOfType = storedModel.value.rooms.filter((r) => r.name === label)
  const totalRoomsOfType = roomsOfType.length

  // Find the current room by its index in the sorted rooms array
  const currentRoom = sortedRooms.value[overallIndex]

  // Calculate the index of this room within its type group
  const roomTypeIndex = roomsOfType.findIndex((r) => r.id === currentRoom.id) + 1

  return totalRoomsOfType > 1 ? `${roomName} (${roomTypeIndex}/${totalRoomsOfType})` : roomName
}

const sortedRooms = computed(() => {
  return [...storedModel.value.rooms].sort((a, b) => {
    if (a.type < b.type) return -1
    if (a.type > b.type) return 1
    return 0
  })
})

const getSortedIndex = (room) => {
  return sortedRooms.value.findIndex((r) => r.id === room.id)
}

const updateRooms = (roomType) => {
  const amount = roomAmounts.value[roomType]

  // Filter out rooms to ignore
  const roomsToIgnore = storedModel.value.toIgnoreRooms || []

  // Keep existing rooms of the current type, including ignored rooms
  const existingRooms = model.value.rooms.filter((room) => room.type === roomType)

  // Keep rooms of other types
  const otherRooms = model.value.rooms.filter((room) => room.type !== roomType)

  // Separate ignored rooms
  const ignoredRooms = existingRooms.filter((room) => roomsToIgnore.includes(room.id))
  let updatedRooms = existingRooms.filter((room) => !roomsToIgnore.includes(room.id))
  // Add new rooms if needed
  while (updatedRooms.length < amount) {
    // Use the original roomType for naming
    const roomName = getRoomName(roomType)

    // Create the room with original name but effective room type
    const newRoom = createRoom(roomName, roomType, false, true)
    updatedRooms.push(newRoom)
  }

  // Remove excess rooms if needed, but don't remove ignored rooms
  if (updatedRooms.length > amount) {
    updatedRooms = updatedRooms.slice(0, amount)
  }

  // Update the rooms array, including ignored rooms
  model.value.rooms = [...otherRooms, ...ignoredRooms, ...updatedRooms]

  // Synchronize storedModel.value.rooms with model.value.rooms
  if (!storedModel.value.rooms) {
    storedModel.value.rooms = []
  }

  // Remove rooms from storedModel that are no longer in model, except ignored rooms
  storedModel.value.rooms = storedModel.value.rooms.filter(
    (storedRoom) =>
      model.value.rooms.some((modelRoom) => modelRoom.id === storedRoom.id) &&
      !roomsToIgnore.includes(storedRoom.id)
  )

  // Add new rooms to storedModel or update existing ones, excluding ignored rooms
  model.value.rooms.forEach((modelRoom) => {
    if (!roomsToIgnore.includes(modelRoom.id)) {
      const existingStoredRoom = storedModel.value.rooms.find(
        (storedRoom) => storedRoom.id === modelRoom.id
      )
      if (existingStoredRoom) {
        // Update existing room, preserving its size
        Object.assign(existingStoredRoom, modelRoom, { areas: existingStoredRoom.areas })
      } else {
        // Add new room to localstorage
        storedModel.value.rooms.push({ ...modelRoom })
      }
    }
  })

  // Clean up roomConfigs, but keep configs for ignored rooms
  Object.keys(model.value.services.wallsCeilings.change.roomConfigs).forEach((roomId) => {
    if (!model.value.rooms.some((room) => room.id === roomId) && !roomsToIgnore.includes(roomId)) {
      delete model.value.services.wallsCeilings.change.roomConfigs[roomId]
    }
  })
}

const hasSelectedRooms = computed(() => {
  return (
    storedModel.value.selectedRoomTypes.every((roomType) => roomAmounts.value[roomType] > 0) &&
    storedModel.value.selectedRoomTypes.length > 0
  )
})

watch(
  roomAmounts,
  (newAmounts, oldAmounts) => {
    Object.keys(newAmounts).forEach((roomType) => {
      updateRooms(roomType)
    })
  },
  { deep: true }
)

watch(storedModel.value.selectedRoomTypes, (newSelectedTypes) => {
  // Reset amount to 0 for deselected room types
  Object.keys(roomAmounts.value).forEach((roomType) => {
    if (!newSelectedTypes.includes(roomType)) {
      roomAmounts.value[roomType] = 0
      updateRooms(roomType)
    }
  })
})

watch(
  () => model.value.rooms,
  (newRooms) => {
    // Remove extra rooms from roomConfigs
    Object.keys(model.value.services.wallsCeilings.change.roomConfigs).forEach((roomId) => {
      if (!newRooms.some((room) => room.id === roomId)) {
        delete model.value.services.wallsCeilings.change.roomConfigs[roomId]
      }
    })
  },
  { deep: true }
)

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  model.value.contact.gclid = getGclid()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(props.modelValue)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'walls',
              content_name: 'walls'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: model.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('walls', data.volume / 100, 'EUR', data.id)
    })
    .finally(() => {
      submitting.value = false
    })
}

onMounted(() => {
  const ignoredRoomIds = model.value.rooms
    .filter(
      (modelRoom) => !storedModel.value.rooms.some((storedRoom) => storedRoom.id === modelRoom.id)
    )
    .map((room) => room.id)

  // Update storedModel with the initial toIgnoreRooms
  storedModel.value.toIgnoreRooms = ignoredRoomIds

  model.value.services.wallsCeilings.change.currentRoom = ''

  if (storedModel.value.roomAmounts) {
    roomAmounts.value = storedModel.value.roomAmounts
  }

  roomTypes.forEach((type) => {
    if (roomAmounts.value[type.value] === undefined) {
      roomAmounts.value[type.value] = 0
    }
  })

  model.value.step = 'services_wallsCeilings_start'
})
</script>
