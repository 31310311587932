const setupTikTok = () => {
  !(function (w, t) {
    w.TiktokAnalyticsObject = t
    var ttq = (w[t] = w[t] || [])

    ttq.methods = [
      'page',
      'track',
      'identify',
      'instances',
      'debug',
      'on',
      'off',
      'once',
      'ready',
      'alias',
      'group',
      'enableCookie',
      'disableCookie',
      'holdConsent',
      'revokeConsent',
      'grantConsent'
    ]

    ttq.setAndDefer = function (t, e) {
      t[e] = function () {
        t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
      }
    }

    for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i])

    ttq.instance = function (t) {
      for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
        ttq.setAndDefer(e, ttq.methods[n])
      return e
    }
  })(window, 'ttq')
}

const setupYandex = () => {
  ;(function (m, i) {
    m[i] =
      m[i] ||
      function () {
        ;(m[i].a = m[i].a || []).push(arguments)
      }
    m[i].l = 1 * new Date()
  })(window, 'ym')
}

export const fast = (data) => {
  window.fast_conversions = window.fast_conversions || []
  window.fast_conversions.push({
    name: 'gclidLead',
    value: data.volume / 100,
    currency: 'EUR',
    bestellnummer: data.id
  })
}

export const yandex = (id, goal, target) => {
  if (typeof ym === 'undefined') setupYandex()

  ym(id, goal, target)
}

export const tiktok = (id, options = {}, identification = null) => {
  if (typeof ttq === 'undefined') setupTikTok()

  ttq.track(id, options)

  if (identification) ttq.identify(identification)
}

export const google = (event) => {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push(event)
}

export const tmViewItem = (calc) => {
  google({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_id: calc,
          item_name: calc,
          index: 0,
          item_brand: 'More at Home',
          item_category: 'Rechner',
          price: 0,
          quantity: 1
        }
      ]
    }
  })
}

export const tmBegin = (calc, amount) => {
  google({
    event: 'begin_checkout',
    ecommerce: {
      currency: 'EUR',
      value: amount,
      items: [
        {
          item_id: calc,
          item_name: calc,
          index: 0,
          item_brand: 'More at Home',
          item_category: 'Rechner',
          price: amount,
          quantity: 1
        }
      ]
    }
  })
}

export const tmPurchase = (calc, amount, currency, transactionId) => {
  google({
    event: 'purchase',
    ecommerce: {
      transaction_id: transactionId,
      currency: currency,
      value: amount,
      items: [
        {
          item_id: calc,
          item_name: calc,
          index: 0,
          item_brand: 'More at Home',
          item_category: 'Rechner',
          price: amount,
          quantity: 1
        }
      ]
    }
  })
}

export const gaServiceTrack = (service, amount) => {
  google({
    service,
    amount,
    event: 'service'
  })
}

export default {
  fast,
  google,
  yandex,
  tiktok,

  tmViewItem,
  tmBegin,
  tmPurchase
}
